var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"gradient-bg white--text",staticStyle:{"margin-top":"-100px"},attrs:{"small":""},on:{"click":function($event){return _vm.add_forms()}}},[_vm._v(" Create Form ")])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"overflow-y-auto overflow mt-n12",attrs:{"height":_vm.height,"dense":"","headers":_vm.MembersDataActive,"items":_vm.MembersItems,"items-per-page":15,"fixed-header":_vm.fixed,"loading":_vm.isLoadingMember,"loading-text":"Loading... Please wait"},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No Data available. ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true},{key:"item.work_type",fn:function(ref){
var item = ref.item;
return [(item.work_type == undefined)?_c('span',{staticClass:"ml-4"},[_vm._v("-")]):_c('span',[_vm._v(_vm._s(item.work_type))])]}},{key:"item.form_created_on",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.get_date(item.form_created_on))}})]}},{key:"item.attachments_supports",fn:function(ref){
var item = ref.item;
return [(item.attachments_supports == 'CAMERA_ONLY')?_c('span',[_vm._v(" Camera")]):(item.attachments_supports == 'GALLERY_ONLY')?_c('span',[_vm._v(" Gallery")]):_c('span',[_vm._v("Camera & Gallery")])]}},{key:"item.is_free_text",fn:function(ref){
var item = ref.item;
return [(item.is_free_text == true)?_c('span',[_vm._v("Yes")]):_c('span',[_vm._v(" No")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.edit_forms(item)}}},[_vm._v(" mdi-pencil")]),_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.delet_forms(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1),(_vm.componentCheck == 1)?_c('div',[_c('FormCreation',{attrs:{"Createforms":_vm.Createforms},on:{"clicked":function($event){_vm.Createforms = false},"nextdialog":_vm.nextstep,"nextlibaray":_vm.nextdialog,"formnamerecive":_vm.store,"errorMsg":_vm.error_info,"successMsg":_vm.success_info}})],1):_vm._e(),(_vm.componentCheck == 2)?_c('div',[_c('ForemsProofwork',{attrs:{"Dynamicform":_vm.Dynamicform,"namesobj":_vm.namesobj},on:{"clicked":function($event){_vm.Dynamicform = false},"errorMsg":_vm.error_info,"successMsg":_vm.success_info,"closedialog":_vm.call}})],1):_vm._e(),(_vm.componentCheck == 3)?_c('div',[_c('DeleteForms',{attrs:{"Formdeletion":_vm.Formdeletion,"formitems":_vm.formitems},on:{"clicked":function($event){_vm.Formdeletion = false},"errorMsg":_vm.error_info,"successMsg":_vm.success_info}})],1):_vm._e(),(_vm.componentCheck == 4)?_c('div',[_c('FormPreview',{attrs:{"previewDialog":_vm.previewDialog,"formFields":_vm.formFields},on:{"clicked":function($event){_vm.previewDialog = false}}})],1):_vm._e(),(_vm.componentCheck == 5)?_c('div',[_c('EditForms',{attrs:{"editforms":_vm.editforms,"formitems":_vm.formitems},on:{"clicked":_vm.checking}})],1):_vm._e(),(_vm.componentCheck == 6)?_c('div',[_c('FormLibraryCreation',{attrs:{"libaryForm":_vm.libaryForm,"namesobj":_vm.namesobj},on:{"clicked":function($event){_vm.libaryForm = false},"errorMsg":_vm.error_info,"successMsg":_vm.success_info,"closedialog":_vm.call}})],1):_vm._e(),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }