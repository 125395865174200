<template>
  <div>
    <v-toolbar flat>
      <v-row class="ml-4 mt-2">
        <v-btn-toggle v-model="toggle_exclusive" mandatory>
          <v-btn
            small
            :class="{
              'white--text': isActive('custom_settings'),
              'black--text': !isActive('custom_settings'),
            }"
            text
            value="custom_settings"
            :style="
              isActive('custom_settings')
                ? { backgroundColor: '#E65100' }
                : { backgroundColor: 'white' }
            "
          >
            Custom Settings
          </v-btn>
          <v-btn
            small
            :class="{
              'white--text': isActive('form_settings'),
              'black--text': !isActive('form_settings'),
            }"
            text
            value="form_settings"
            :style="
              isActive('form_settings')
                ? { backgroundColor: '#E65100' }
                : { backgroundColor: 'white' }
            "
          >
            Form Design
          </v-btn>
        </v-btn-toggle>
      </v-row>
      <v-spacer />
    </v-toolbar>
    <div class="text-left" v-if="toggle_exclusive == 'custom_settings'">
      <ProofCustomSetting />
    </div>
    <div class="text-left" v-if="toggle_exclusive == 'form_settings'">
      <ProofFormSetting />
    </div>
  </div>
</template>
      
      <script>
import ProofCustomSetting from "@/views/ChildHomePages/Settings/ProofCustomSetting.vue";
import ProofFormSetting from "@/views/ChildHomePages/Settings/ProofFormSetting.vue";

export default {
  components: {
    ProofCustomSetting,
    ProofFormSetting,
  },
  data: () => ({
    toggle_exclusive: "custom_settings",
  }),
  computed: {
    isActive() {
      return (value) => this.toggle_exclusive === value;
    },
  },
  methods: {},
};
</script>
      
      <style scoped>
.active-button {
  /* Add your active background color here */
  background-color: blue;
}

.inactive-button {
  /* Add your inactive background color here */
  background-color: white;
}
</style>