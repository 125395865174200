<template>
  <div>
    <v-dialog v-model="Createforms" persistent max-width="350">
      <v-card>
        <v-toolbar dense class="gradient-bg elevation-0">
          <v-toolbar-title class="white--text">Create Form </v-toolbar-title>
          <v-spacer />

          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text class="mt-4" v-if="formCheck == false">
          <v-form ref="form">
            <v-text-field
              v-model="formName"
              outlined
              dense
              :rules="rules_scan_anywhere"
              style="max-width: 300px"
              label="Form Name"
            />

            <v-select
              label="Work Types"
              outlined
              dense
              :items="worktypeitems"
              item-text="location_name"
              item-value="location_id"
              v-model="worktypes"
              style="max-width: 300px"
            />
            <!-- <v-select
              label="Attachment Types"
              outlined
              dense
              style="max-width: 300px"
              item-text="name"
              item-value="value"
              :rules="[(v) => !!v || 'Required']"
              :items="attxhmentitems"
              v-model="attachements"
            /> -->
          </v-form>
        </v-card-text>
        <v-card-text class="mt-4" v-if="formCheck == true">
          <v-row no-gutters>
            <v-col cols="6">
              <v-card @click="form_library()" height="100px">
                <v-card-text class="justify-center">
                  <v-icon color="primaryColor" large> mdi-form-select </v-icon>
                  <br />
                  <div class="mt-2">Form Library</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card @click="get_custom()" class="ml-2" height="100px">
                <v-card-text class="justify-center">
                  <v-icon color="primaryColor" large>
                    mdi-note-edit-outline</v-icon
                  >
                  <br />
                  <div class="mt-2">Custom Form</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="gradient-bg white--text mb-2"
            @click="dynamic()"
            v-show="formCheck == false"
          >
            Next
          </v-btn>
          <v-btn
            class="gradient-bg white--text mb-2"
            @click="dynamiback()"
            v-show="formCheck == true"
          >
            Back
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { create_forms } from "@/graphql/mutations.js";
import { scan_any_where_response_form_list_or_edit } from "@/graphql/mutations.js";
export default {
  props: {
    Createforms: Boolean,
  },
  data() {
    return {
      formName: "",
      loading: false,
      formCheck: false,
      worktypes: "",
      formobject: {},
      worktypeitems: [],
      attachements: "",
      attxhmentitems: [
        { name: "Camera", value: "CAMERA_ONLY" },
        { name: "Gallery", value: "GALLERY_ONLY" },
        { name: "Both", value: "BOTH_CAMERA_GALLERY" },
      ],
      rules_scan_anywhere: [
        (v) =>
          (typeof v === "string" && v.trim().length !== 0) ||
          " Form Name cannot be empty",
        (v) =>
          /^[a-z\sA-Z]{1,30}$/.test(v) ||
          " Form Name cannot contain special characters ",
      ],
    };
  },
  created() {},
  watch: {
    Createforms: {
      async handler() {
        {
          this.formCheck = false;
          this.formName='',
          await this.getScanAnyWhereList();
          //   console.log(this.groupinfo);
        }
      },
      immediate: true,
    },
  },
  methods: {
    get_custom() {
      this.formobject = {
          name: this.formName,
          worktype: this.worktypes,
          attachements: this.attachements,
        };
      this.$emit("formnamerecive", this.formobject);

      this.$emit("nextdialog", 0);
    },
    dynamiback() {
      this.formCheck = false;
    },
    form_library() {
      this.formobject = {
          name: this.formName,
          worktype: this.worktypes,
          attachements: this.attachements,
        };
        this.$emit("formnamerecive", this.formobject);
      this.$emit("nextlibaray", 0);
    },
    async getScanAnyWhereList() {
      try {
        var inputParams = {
          action_type: "LIST",
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
        };
        let result = await API.graphql(
          graphqlOperation(scan_any_where_response_form_list_or_edit, {
            input: inputParams,
          })
        );

        // console.log(
        //   JSON.parse(result.data.scan_any_where_response_form_list_or_edit)
        //     .Items[0].list_response_message
        // );

        var response = JSON.parse(
          result.data.scan_any_where_response_form_list_or_edit
        ).Items[0].list_response_message;

        response.forEach((element) => {
          if (element.master_form_id == null) {
            this.worktypeitems.push({
              location_name: element.work_type,
              location_id: element.work_type,
            });
          }
        });
      } catch (err) {
        console.log(err);
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
      }
    },
    close_dialog() {
      this.column = null;
      this.formCheck = false;
      this.$emit("clicked", 0);
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    freeForm() {
      if (this.$refs.form.validate()) {
        this.create_freeforms();
      }
    },
    dynamic() {
      if (this.$refs.form.validate()) {
        this.formobject = {
          name: this.formName,
          worktype: this.worktypes,
          attachements: this.attachements,
        };
        this.formCheck = true;
        // console.log(this.formobject);
      }
    },
    async create_freeforms() {
      this.loading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(create_forms, {
            input: {
              attachments_supports: this.attachements,

              form_name: this.formName,
              is_free_text: true,
              form_created_by:
                this.$store.getters.get_org_user_details[0].user_email_id,

              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );
        // console.log(result);
        var response = JSON.parse(result.data.create_forms);
        this.loading = false;
        if (response.Status == "SUCCESS") {
          this.$emit("successMsg", response.Message);
          this.loading = false;
        } else {
          this.$emit("errorMsg", response.Message);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.$emit("errorMsg", error.errors[0].message);
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>