<template>
  <div>
    <v-dialog v-model="libaryForm" persistent max-width="1100">
      <v-card flat>
        <v-toolbar dense class="gradient-bg elevation-0">
          <v-toolbar-title class="white--text">Form Design </v-toolbar-title>
          <v-spacer />

          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          >
        </v-toolbar>

        <v-card-text class="mt-3">
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="12"
              md="4"
              xs="12"
              lg="4"
              fill-height
              v-if="formlibcheck == true"
            >
              <v-card
                v-if="componentCheck == false"
                :height="cardHeight"
                flat
                over
                class="card-content"
              >
                <div class="text-center primary--text" style="font-size: 25px">
                  <b>Add Fields</b>
                </div>
                <v-row class="mt-2" no-gutters>
                  <v-col v-for="item in inputFields" :key="item.type" cols="12">
                    <v-btn
                      @click="fetch_selected_item(item)"
                      style="
                        width: 250px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                      "
                      class="ma-1 ml-2 text-left"
                    >
                      <v-icon left> {{ item.icon }} </v-icon>
                      {{ item.label }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                :height="cardHeight"
                class="elevation-0 card-content"
                v-if="componentCheck == true"
              >
                <v-toolbar dense class="elevation-0">
                  <div><b>Edit Field</b></div>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    class="gradient-bg white--text"
                    @click="back_action()"
                    ><v-icon>mdi-chevron-double-left</v-icon> Back</v-btn
                  >
                </v-toolbar>
                <v-card-text>
                  <v-form ref="form1">
                    <v-text-field
                      dense
                      readonly
                      v-model="selectedItem.type"
                      label="Field Type"
                      outlined
                    />

                    <v-text-field
                      dense
                      outlined
                      v-model="labelToDisplay"
                      label="Label To Display"
                      :rules="rules_scan_anywhere"
                      @input="get_input_value(labelToDisplay)"
                      :readonly="isReadonly"
                    />
                    <v-checkbox
                      v-show="
                        selectedItem.backend_type != 'label' &&
                        selectedItem.backend_type != 'delimiter'
                      "
                      v-model="madatoryField"
                      label="Mandatory Field*"
                    ></v-checkbox>
                    <!-- <v-checkbox
                        v-model="Readonly"
                        label="Edit Field*"
                        class="mt-n3"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="visibel"
                        label="Visible Field*"
                        class="mt-n3"
                      ></v-checkbox> -->
                  </v-form>
                  <div v-if="selectedItem.type == 'DROPDOWN'">
                    <v-form ref="form">
                      <v-row no-gutters>
                        <v-col>
                          <v-text-field
                            outlined
                            dense
                            v-model="fieldData"
                            :rules="[(v) => !!v || 'Required']"
                            label="Options"
                            style="width: 210px"
                          />
                        </v-col>
                        <v-col>
                          <v-btn
                            @click="add_options(fieldData)"
                            class="elevation-0 ml-5"
                            fab
                            x-small
                          >
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in globalOptions"
                        :key="item"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon @click="delete_data(index)" small
                            >mdi-delete</v-icon
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </div>

                  <v-btn
                    class="gradient-bg white--text"
                    @click="add_field()"
                    v-if="editable == false"
                    style="width: 250px"
                    >Add Field
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="8" xs="12" v-if="formlibcheck == true">
              <!-- <template> -->
              <AdminFormView
                :formFields="formFields"
                :namesobj="namesobj"
                v-on:errorMsg="error_info"
                v-on:successMsg="success_info"
                v-on:editValue="get_edit_field"
              />
              <!-- </template> -->
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              xs="12"
              lg="6"
              v-if="formlibcheck == false"
            >
              <v-data-table
                :height="tabelHeight"
                :headers="AdminData"
                :items="AdminItems"
                :items-per-page="100"
                :loading="isLoadingAdmin"
                loading-text="Loading... Please wait"
                class="overflow-y-auto mx-4"
                :fixed-header="fixed"
                :search="search"
                dense
                :footer-props="{
                  'items-per-page-options': [10, 15, 20, 25],
                }"
                @click:row="handleClick"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    class="gradient-bg white--text"
                    x-small
                    @click="view_form(item)"
                    >Use Template</v-btn
                  >
                </template>
              </v-data-table>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="6"
              xs="12"
              lg="6"
              v-if="formlibcheck == false"
            >
              <v-card :height="previewheight" class="overflow-y-auto">
                <v-card-text class="mt-5">
                  <div
                    align-center
                    justify-center
                    v-if="formFields2.length != 0"
                  >
                    <b>Preview</b>
                  </div>
                  <div v-if="formFields2.length == 0">
                    <b>No Preview Available</b>
                  </div>
                  <v-row no-gutters>
                    <v-col
                      v-for="(index, Idx) in formFields2"
                      :key="Idx"
                      :cols="Idx === 0 ? '12' : '12 mt-n4'"
                      sm="12"
                      xs="12"
                      md="12"
                    >
                      <TextField
                        :formActionType="formActionType"
                        v-if="
                          index.type == 'TEXTFIELD' ||
                          index.type == 'EMAIL' ||
                          index.type == 'NAME' ||
                          index.type == 'MOBILENUMBER'
                        "
                        :index="index"
                      />
                      <NumberField
                        v-if="index.type == 'NUMBER'"
                        :index="index"
                        :formActionType="formActionType"
                      />
                      <CheckboxField
                        class="mt-n4"
                        v-if="index.type == 'CHECKBOX'"
                        :index="index"
                        :formActionType="formActionType"
                      />
                      <TextAreaField
                        v-if="index.type == 'PARAGRAPH'"
                        :index="index"
                        :formActionType="formActionType"
                      />
                      <DateField
                        v-if="index.type == 'DATE'"
                        :index="index"
                        :formActionType="formActionType"
                      />
                      <DropdowmField
                        v-if="index.type == 'DROPDOWN'"
                        :index="index"
                        :formActionType="formActionType"
                      />
                      <TimePicker
                        v-if="index.type == 'TIME'"
                        :index="index"
                        :formActionType="formActionType"
                      />
                      <RatingFile
                        class="mt-n4"
                        v-if="index.type == 'RATING'"
                        :index="index"
                        :formActionType="formActionType"
                      />
                      <DividerComp
                        class="mt-2"
                        v-if="index.type == 'DELIMITER'"
                        :index="index"
                        :formActionType="formActionType"
                      />
                      <FlipSwitch
                        v-if="index.type == 'FLIPSWITCH'"
                        :index="index"
                        class="mt-n4"
                        :formActionType="formActionType"
                      />
                      <LabelField
                        v-if="index.type == 'LABEL'"
                        :index="index"
                        :formActionType="formActionType"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>
    <script>
/*eslint-disable*/
import { API, graphqlOperation } from "aws-amplify";
import { list_forms_library } from "@/graphql/queries.js";
import AdminFormView from "@/components/FormDesigner/AdminFormView.vue";
import SnackBar from "@/components/SnackBar.vue";
import LabelField from "@/components/FormDesigner/FormFields/LabelField.vue";
import TextField from "@/components/FormDesigner/FormFields/TextField.vue";
import NumberField from "@/components/FormDesigner/FormFields/NumberField.vue";
import CheckboxField from "@/components/FormDesigner/FormFields/CheckboxField.vue";
import TextAreaField from "@/components/FormDesigner/FormFields/TextAreaField.vue";
import DateField from "@/components/FormDesigner/FormFields/DateField.vue";
import DropdowmField from "@/components/FormDesigner/FormFields/DropdowmField.vue";
import TimePicker from "@/components/FormDesigner/FormFields/TimePicker.vue";
import RatingFile from "@/components/FormDesigner/FormFields/RatingFile.vue";
import DividerComp from "@/components/FormDesigner/FormFields/DividerComp.vue";
import FlipSwitch from "@/components/FormDesigner/FormFields/FlipSwitch.vue";
export default {
  props: {
    libaryForm: Boolean,
    namesobj: Object,
  },
  components: {
    AdminFormView,
    SnackBar,
    TextField,
    NumberField,
    CheckboxField,
    TextAreaField,
    DateField,
    DropdowmField,
    TimePicker,
    RatingFile,
    DividerComp,
    FlipSwitch,
    LabelField,
  },
  data() {
    return {
      formAction: "",
      formActionType: "preview",
      formlibcheck: false,
      SnackBarComponent: {},
      previewheight: 0,
      isLoadingAdmin: false,
      fixed: true,
      search: "",
      AdminItems: [],
      formFields2: [],
      AdminData: [
        { text: "Form Name", value: "form_name" },
        { text: "Form Description", value: "form_description" },
        { text: "Action", value: "actions" },
      ],
      rules_scan_anywhere: [
        (v) =>
          (typeof v === "string" && v.trim().length !== 0) ||
          " Filed cannot be empty",
        (v) =>
          /^[a-z\sA-Z]{1,40}$/.test(v) ||
          "Filed cannot contain special characters and more than 40 characters ",
      ],
      inputFields: [
        {
          type: "TEXTFIELD",
          icon: "mdi-text-recognition",
          label: "Text",
          backend_type: "string",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "PARAGRAPH",
          icon: "mdi-text-box",
          label: "Paragraph",
          backend_type: "paragraph",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "NUMBER",
          icon: "mdi-numeric",
          label: "Number",
          backend_type: "number",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "MOBILENUMBER",
          icon: "mdi-phone",
          label: "Mobile Number",
          backend_type: "number",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "EMAIL",
          icon: "mdi-email",
          label: "Email",
          backend_type: "email",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "DROPDOWN",
          icon: "mdi-format-list-bulleted-square",
          label: "Dropdown",
          backend_type: "single_choice",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "DELIMITER",
          icon: "mdi-format-line-spacing",
          label: "Delimiter",
          backend_type: "delimiter",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },

        {
          type: "LABEL",
          icon: "mdi-label",
          label: "Label",
          backend_type: "label",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "CHECKBOX",
          icon: "mdi-checkbox-outline",
          label: "Checkbox",
          backend_type: "checkbox",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "TIME",
          icon: "mdi-timer",
          label: "Time",
          backend_type: "time",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "DATE",
          icon: "mdi-calendar",
          label: "Date",
          backend_type: "date",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        // {
        //   type: "NAME",
        //   icon: "mdi-account",
        //   label: "Name",
        //   backend_type: "string",
        //   is_displayed: true,
        //   is_readonly: false,
        //   is_required: true,
        // },
        {
          type: "FLIPSWITCH",
          icon: "mdi-toggle-switch",
          label: "Flip Switch",
          backend_type: "boolean",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "RATING",
          icon: "mdi-star-outline",
          label: "Ratings",
          backend_type: "rating",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
      ],
      cardHeight: 0,
      editable: false,
      componentCheck: false,
      selectedItem: "",
      labelToDisplay: "",
      formFields: [],
      count: 0,
      tabelHeight: 0,
      fieldData: "",
      globalOptions: [],
    };
  },
  watch: {
    libaryForm: {
      async handler() {
        this.cardHeight = window.innerHeight - 170;
        this.tabelHeight = window.innerHeight - 250;
        this.previewheight = window.innerHeight - 200;
        await this.listlibraryform();
        // console.log(this.namesobj);
      },
      immediate: true,
    },
  },
  computed: {
    isReadonly() {
      return this.selectedItem.backend_type === "divider";
    },
  },
  methods: {
    error_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
    },
    get_edit_field(value) {
      this.selectedItem = {};
      this.labelToDisplay = "";
      this.componentCheck = true;
      this.selectedItem = value;
      this.labelToDisplay = value.label;
      this.madatoryField = value.is_required;
      this.editable = true;
      this.globalOptions = value.fieldOptions;
    },
    get_input_value(value) {
      if (this.editable == true) {
        this.formFields.forEach((element) => {
          if (element.label == this.selectedItem.label) {
            element.label = value;
            element.displayLabel = value;
          }
        });
      }
    },
    success_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };

      this.$emit("closedialog", 0);
    },
    close_dialog() {
      this.$emit("clicked", 0);
    },
    fetch_selected_item(value) {
      this.selectedItem = {};
      this.labelToDisplay = "";
      this.componentCheck = true;
      this.selectedItem = value;
      this.labelToDisplay = value.label;
      this.madatoryField = true;
      this.Readonly = true;
      this.visibel = true;
      this.globalOptions = [];
    },
    handleClick(val) {
      console.log(val);
      this.formFields2 = val.form_desgin;
      // console.log(this.formFields);
    },
    async listlibraryform() {
      this.isLoadingAdmin = true;
      this.overlay = true;
      try {
        this.MembersItems = [];
        let result = await API.graphql(
          graphqlOperation(list_forms_library, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              limit: 50,
              nextToken: null,
            },
          })
        );

        var response = JSON.parse(result.data.list_forms_library);
        // console.log(response);
        if (response.Status == "SUCCESS") {
          this.AdminItems = response.data;
          this.formFields2 = response.data[0].form_desgin;
          // console.log(this.formFields);
        } else {
          this.AdminItems = [];
          this.formFields2 = [];
        }
        // console.log(" this.MembersItems ", this.AdminItems);

        this.isLoadingAdmin = false;
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.isLoadingAdmin = false;
        this.overlay = false;
      }
    },
    add_field() {
      // console.log(this.globalOptions);
      if (this.selectedItem.type == "DROPDOWN") {
        if (this.globalOptions.length >= 1) {
          if (this.$refs.form1.validate()) {
            this.selectedItem.displayLabel = "";

            this.formFields.push({
              type: this.selectedItem.type,
              label: this.labelToDisplay,
              icon: this.selectedItem.icon,
              backend_type: this.selectedItem.backend_type,
              displayLabel: this.labelToDisplay,
              is_displayed: this.visibel,
              is_readonly: this.Readonly,
              is_required: this.madatoryField,
              fieldOptions: this.globalOptions,
            });
            this.globalOptions = [];
          }
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: "Add atleast One Option",
            timeout: 5000,
            Top: true,
          };
        }
      } else if (this.$refs.form1.validate()) {
        this.selectedItem.displayLabel = "";
        if (
          this.selectedItem.type == "DELIMITER" ||
          this.selectedItem.type == "LABEL"
        ) {
          this.madatoryField = false;
        }
        this.formFields.push({
          type: this.selectedItem.type,
          label: this.labelToDisplay,
          icon: this.selectedItem.icon,
          backend_type: this.selectedItem.backend_type,
          displayLabel: this.labelToDisplay,
          is_displayed: this.visibel,
          is_readonly: this.Readonly,
          is_required: this.madatoryField,
          fieldOptions: this.globalOptions,
        });
        this.globalOptions = [];
      }
      // console.log(this.formFields);
    },

    back_action() {
      if (this.editable == true) {
        if (this.selectedItem.type == "DROPDOWN") {
          if (this.globalOptions.length == 0) {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Select atleast One Option",
              timeout: 5000,
              Top: true,
            };
          } else if (this.labelToDisplay == "") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Enter the label to display",
              timeout: 5000,
              Top: true,
            };
          } else {
            this.componentCheck = 0;
            this.editable = false;
            this.labelToDisplay = "";
          }
        } else {
          if (this.labelToDisplay == "") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Enter the label to display",
              timeout: 5000,
              Top: true,
            };
          } else {
            this.componentCheck = 0;
            this.editable = false;
            this.labelToDisplay = "";
          }
        }
      } else {
        this.componentCheck = false;
        this.editable = false;
        this.labelToDisplay = "";
      }
    },

    add_options(value) {
      if (this.$refs.form.validate()) {
        if (this.globalOptions.includes(value)) {
          this.$store.commit("snackbar/showMessage", {
            content: "Already exists ",
            color: "red",
          });
        } else {
          this.globalOptions.push(value);
        }
      }
      this.fieldData = "";
      this.$refs.form.reset();
    },

    delete_data(val) {
      this.globalOptions.splice(val, 1);
    },
    view_form(item) {
      this.formlibcheck = true;
      this.formFields = item.form_desgin;
    },
  },
};
</script>
    <style scoped>
.card-content {
  overflow: auto; /* Add overflow property to handle content overflow */
}
</style>