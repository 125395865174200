<template>
    <div>
      <v-dialog v-model="untaggingForm" persistent width="390px">
        <v-card class="overflow-hidden">
          <v-toolbar dense class="gradient-bg elevation-0 white--text">
            <v-toolbar-title>Untag Form</v-toolbar-title><v-spacer />
            <v-icon @click="close_dialog()" class="white--text mr-n3"
              >mdi-close</v-icon
            >
          </v-toolbar>
          <v-card-text
            align="center"
            style="font-weight: bold; text-align: justify"
          >
            <div class="mt-5">
              Would you like to Untag Form for  {{ tagitem.work_type }}?
            </div>
          </v-card-text>
          <div class="d-flex justify-center mb-3">
            <v-card-actions class="">
              <v-btn
                color="primary"
                class="white--text"
                @click="close_dialog()"
                small
                >No</v-btn
              >
              <v-btn
                color="primary"
                class="white--text"
                :loading="loading"
                @click="delete_freeforms()"
                small
                >Yes</v-btn
              >
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </template>
        <script>
  import { API, graphqlOperation } from "aws-amplify";
  import { tag_untag_forms } from "@/graphql/mutations.js";
  export default {
    components: {},
    props: {
      untaggingForm: Boolean,
      tagitem: Object,
    },
    data: () => ({
      loading: false,
    }),
    watch: {
      untaggingForm: {
        handler() {
          {
              // console.log(this.tagitem);
          }
        },
        immediate: true,
      },
    },
    methods: {
      close_dialog() {
        this.$emit("clicked", 0);
      },
      async delete_freeforms() {
      this.adminLoading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(tag_untag_forms, {
            input: {
              action_type: "UNTAG",
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              form_id: this.tagitem.master_form_id,
              work_type: this.tagitem.work_type,
            },
          })
        );
        // console.log(result);
        var response = JSON.parse(result.data.tag_untag_forms);
        this.adminLoading = false;
        if (response.Status == "SUCCESS") {
          this.$emit("successMsg", response.Message);
          this.adminLoading = false;
        } else {
          this.$emit("errorMsg", response.Message);
          this.adminLoading = false;
        }
      } catch (error) {
        console.log(error);
        this.$emit("errorMsg", error.errors[0].message);
        this.adminLoading = false;
      }
    },
    },
  };
  </script>