<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row class="mt-n8">
          <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6">
            <v-card class="mt-7 mx-1 mr-2 elevation-1">
              <v-toolbar flat>
                <v-card-subtitle
                  ><b>Proof of Presence Settings</b>
                </v-card-subtitle>
                <v-switch
                  v-model="proofwork"
                  dense
                  inset
                  class="mt-6 mr-5"
                  :label="`${
                    proofwork.toString() == 'true' ? 'Enable' : 'Disable'
                  }`"
              /></v-toolbar>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-toolbar flat class="mt-n4">
                      <v-icon color="primary" left> mdi-line-scan </v-icon>
                      Simple 
                      <v-spacer />
                      <v-switch
                        v-model="forms.is_simple_scan_enabled"
                        dense
                        :label="`${
                          forms.is_simple_scan_enabled.toString() == 'true'
                            ? 'Enable'
                            : 'Disable'
                        }`"
                        inset
                        :disabled="proofwork == false"
                        class="mt-5"
                      >
                      </v-switch>
                    </v-toolbar>
                  </v-col>
                  <v-col cols="12">
                    <v-toolbar flat class="mt-n8">
                      <v-icon color="primary" left> mdi-qrcode </v-icon>
                      QR Scan
                      <v-spacer />
                      <v-switch
                        v-model="forms.is_qr"
                        dense
                        :label="`${
                          forms.is_qr.toString() == 'true'
                            ? 'Enable'
                            : 'Disable'
                        }`"
                        inset
                        :disabled="proofwork == false"
                        class="mt-2"
                      >
                      </v-switch>
                    </v-toolbar>
                  </v-col>
                  <v-col cols="12">
                    <v-toolbar flat class="mt-n8">
                      <v-icon color="black" left> mdi-bluetooth-audio </v-icon>
                      Beacon
                      <v-spacer />
                      <v-switch
                        v-model="forms.is_beacon"
                        dense
                        :label="`${
                          forms.is_beacon.toString() == 'true'
                            ? 'Enable'
                            : 'Disable'
                        }`"
                        inset
                        :disabled="proofwork == false"
                        class="mt-2"
                      >
                      </v-switch>
                    </v-toolbar>
                  </v-col>
                  <v-col cols="12">
                    <v-toolbar flat class="mt-n9">
                      <v-icon color="green" left> mdi-face-recognition </v-icon>
                      Face 
                      <v-spacer />
                      <v-switch
                        v-model="forms.is_face"
                        dense
                        :label="`${
                          forms.is_face.toString() == 'true'
                            ? 'Enable'
                            : 'Disable'
                        }`"
                        inset
                        :disabled="proofwork == false"
                        class="mt-4"
                        @change="faceforms"
                      >
                      </v-switch>
                    </v-toolbar>
                  </v-col>
                  <div v-if="forms.is_face == true">
                    <v-row>
                      <v-col cols="12">
                        <v-toolbar flat class="ml-12 mt-n8">
                          Scan Anywhere<br />
                          (Outside the organization’s geofenced locations)

                          <v-spacer />
                          <v-switch
                            v-model="forms.is_face_scan_where"
                            dense
                            :label="`${
                              forms.is_face_scan_where.toString() == 'true'
                                ? 'Enable'
                                : 'Disable'
                            }`"
                            class="mt-5 mr-3"
                            :disabled="proofwork == false"
                            inset
                            @change="scanloc()"
                          >
                          </v-switch>
                        </v-toolbar>
                      </v-col>
                      <v-col cols="12">
                        <v-toolbar flat class="mt-n7 ml-12">
                          All Geo Location <br />
                          (Marked by Organisation)
                          <v-spacer />
                          <v-switch
                            v-model="forms.is_face_all_over"
                            dense
                            :label="`${
                              forms.is_face_all_over.toString() == 'true'
                                ? 'Enable'
                                : 'Disable'
                            }`"
                            class="mt-5 mr-3"
                            :disabled="proofwork == false"
                            inset
                            @change="alloc()"
                          >
                          </v-switch>
                        </v-toolbar>
                      </v-col>
                    </v-row>
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6">
            <v-card class="mt-7 mb-5 mx-1 elevation-1">
              <v-card-title style="font-size: 14px; color: #424242">
                Proof of Work Configuration
              </v-card-title>

              <v-form ref="form1">
                <v-row>
                  <v-col cols="5">
                    <v-text-field
                      label="Work Type"
                      dense
                      :rules="rules_scan_anywhere"
                      outlined
                      v-model="addCaptureValue"
                      class="ml-4"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      v-model="attachements"
                      row
                      label="Attachment Source"
                      class="mt-n3"
                      :rules="[(v) => !!v || 'Required']"
                    >
                      <v-radio label="Camera" value="CAMERA_ONLY"></v-radio>
                      <v-radio
                        label="Both"
                        value="BOTH_CAMERA_GALLERY"
                      ></v-radio>
                    </v-radio-group>
                    <!-- <v-select
                      label="Attachment Source"
                      outlined
                      dense
                      item-text="name"
                      item-value="value"
                      :rules="[(v) => !!v || 'Required']"
                      :items="attxhmentitems"
                      v-model="attachements"
                    /> -->
                  </v-col>

                  <v-col cols="1" sm="1" xs="1" md="1" lg="1" xl="1">
                    <v-btn
                      small
                      color="primary"
                      class="white--text mt-3"
                      @click="addData(addCaptureValue)"
                    >
                      <v-icon dark>mdi-plus</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
              <v-card-text>
                <v-data-table
                  :height="height"
                  :fixed-header="fixed"
                  :headers="headers"
                  :items="DataItems"
                  :loading="tableLoading"
                  :items-per-page="100"
                  :sort-by="['work_type']"
                  dense
                  class="overflow-y-auto mt-n5"
                  hide-default-footer
                >
                  <template v-slot:[`item.form_name`]="{ item }">
                    <span v-if="item.form_name != undefined"
                      >{{ item.form_name }}
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            small
                            color="red"
                            class="ml-2"
                            @click="form_untagging(item)"
                            >mdi-alpha-x-box-outline</v-icon
                          >
                        </template>
                        <span class="white--text"> Untag Form</span>
                      </v-tooltip>
                    </span>
                    <v-tooltip bottom color="primary" v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="form_tagging(item)"
                          x-small
                          rectangle
                          v-on="on"
                          class="gradient-bg white--text ml-1"
                          ><v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span class="white--text"> Tag Form</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:[`item.work_type`]="{ item }">
                    <span>{{ item.work_type }}</span>
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          color="primary"
                          class="ml-2"
                          v-on="on"
                          v-bind="attrs"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <!-- <span class="white--text"> Edit Source</span> -->
                      <span
                        class="white--text"
                        v-if="item.attachments_supports == 'CAMERA_ONLY'"
                        >Camera</span
                      >
                      <span
                        class="white--text"
                        v-else-if="item.attachments_supports == 'GALLERY_ONLY'"
                        >Gallery</span
                      >
                      <span class="white--text" v-else>Camera & Gallery</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.attachments_supports`]="{ item }">
                    <span v-if="item.attachments_supports == 'CAMERA_ONLY'">
                      Camera</span
                    >
                    <span
                      v-else-if="item.attachments_supports == 'GALLERY_ONLY'"
                    >
                      Gallery</span
                    >
                    <span v-else>Camera & Gallery</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                      v-show="DataItems.length > 1"
                      small
                      :disabled="
                        item.master_form_id != null ||
                        item.work_type == 'Proof Of Presence'
                      "
                      @click="open_delete(item)"
                      color="red"
                      >mdi-delete</v-icon
                    >
                    <!-- @click="delete_feature(item)" -->
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-toolbar flat class="mt-2"
          ><v-spacer />
          <v-btn
            small
            class="gradient-bg white--text pa-4"
            :loading="saveloader"
            @click="save_both()"
            ><v-icon class="mr-2">mdi-content-save</v-icon> Save Changes
          </v-btn>
        </v-toolbar>
      </v-card-text>
    </v-card>
    <v-dialog v-model="Formdeletion" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0 white--text">
          <v-toolbar-title>Delete Proof of Work Configuration</v-toolbar-title
          ><v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <div class="mt-5">
            Would you like to Delete {{ deleteObject.work_type }} ?
          </div>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="">
            <v-btn
              color="primary"
              class="white--text"
              @click="close_dialog()"
              small
              >No</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              @click="delete_feature(deleteObject)"
              small
              >Yes</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <div v-if="componentCheck == 1">
      <TagForm
        :taggingForm="taggingForm"
        @clicked="taggingForm = false"
        :tagitem="tagitem"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
      />
    </div>
    <div v-if="componentCheck == 2">
      <UntagForm
        :untaggingForm="untaggingForm"
        @clicked="untaggingForm = false"
        :tagitem="tagitem"
        v-on:errorMsg="error_info"
        v-on:successMsg="success_info"
      />
    </div>
  </div>
</template>

<script>
import Snackbar from "@/components/SnackBar.vue";
import Overlay from "@/components/Overlay.vue";
import { GetCurrentUserDetailsnew } from "@/mixins/getCurrentUserDetails.js";
import { API, graphqlOperation } from "aws-amplify";
import {
  proof_of_prezence_settings,
  scan_any_where_response_form_list_or_edit,
} from "@/graphql/mutations.js";
import TagForm from "@/components/Dialogs/TagForm.vue";
import UntagForm from "@/components/Dialogs/UntagForm.vue";
export default {
  components: {
    Overlay,
    Snackbar,
    TagForm,
    UntagForm,
  },
  mixins: [GetCurrentUserDetailsnew],
  data: () => ({
    DataItems: [],
    feedid: "",
    height: 0,
    attachements: "",
    componentCheck: 0,
    status: false,
    attxhmentitems: [
      { name: "Camera", value: "CAMERA_ONLY" },
      { name: "Gallery", value: "GALLERY_ONLY" },
      { name: "Both", value: "BOTH_CAMERA_GALLERY" },
    ],
    proofwork: false,
    SnackBarComponent: {},
    Formdeletion: false,
    rules_scan_anywhere: [
      (v) =>
        (typeof v === "string" && v.trim().length !== 0) ||
        " Reason cannot be empty",
      (v) =>
        /^[a-z\sA-Z]{1,30}$/.test(v) ||
        " Reason for Proof Of Work cannot contain special characters ",
    ],
    data_id: [],
    overlay: false,
    saveloader: false,
    is_face_scan_where: false,
    is_face_all_over: false,
    tableLoading: false,
    taggingForm: false,
    untaggingForm: false,
    deleteObject: {},
    is_beacon: false,
    addCaptureValue: [],
    fixed: true,
    forms: {},
    tableData: [],
    tagitem: {},
    headers: [
      {
        text: "Work Type",
        value: "work_type",
        sortable: false,
      },
      // {
      //   text: "Attachment Source",
      //   value: "attachments_supports",
      //   sortable: false,
      // },
      {
        text: "Tagged Form",
        value: "form_name",
        sortable: false,
      },
      { text: "Action", value: "actions", sortable: false },
    ],
  }),
  created() {
    this.height = window.innerHeight - 410;
    this.getScanAnyWhereList();
    this.GetCurrentUserDetailsnew();
    this.get_data();
  },
  methods: {
    close_dialog() {
      this.Formdeletion = false;
    },
    error_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
    },

    success_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
      (this.untaggingForm = false),
        (this.taggingForm = false),
        this.getScanAnyWhereList();
      this.GetCurrentUserDetailsnew();
      this.get_data();
    },
    form_tagging(item) {
      this.tagitem = item;
      this.componentCheck = 1;
      this.taggingForm = true;
    },
    form_untagging(item) {
      this.tagitem = item;
      this.componentCheck = 2;
      this.untaggingForm = true;
    },
    open_delete(item) {
      this.deleteObject = item;
      this.Formdeletion = true;
    },
    get_data() {
      if (this.$store.getters.get_org_details[0].forms_enable != null) {
        delete this.$store.getters.get_org_details[0].forms_enable.__typename;
      }

      this.proofwork =
        this.$store.getters.get_org_details[0].is_proof_of_work_enable;
      if (this.$store.getters.get_org_details[0].forms_enable != null) {
        this.forms = this.$store.getters.get_org_details[0].forms_enable;
        if (this.forms.is_simple_scan_enabled == null) {
          this.forms.is_simple_scan_enabled = true;
        }
        if (
          [true, false].includes(this.forms.is_face) &&
          this.forms.is_face_all_over == null
        ) {
          this.forms = Object.assign({
            ...this.$store.getters.get_org_details[0].forms_enable,
            is_face_all_over: this.forms.is_face ? false : false,
            is_face_scan_where: this.forms.is_face ? true : false,
          });
        }
      } else {
        this.forms = {
          is_face: true,
          is_qr: false,
          is_beacon: false,
          is_face_scan_where: true,
          is_face_all_over: false,
          is_simple_scan_enabled: false,
        };
      }
    },
    faceforms() {
      if (this.forms.is_face == true) {
        this.forms.is_face_scan_where = true;
      }
    },
    alloc() {
      if (this.forms.is_face_all_over == true) {
        this.forms.is_face_scan_where = true;
      } else if (this.forms.is_face_all_over == false) {
        this.forms.is_face_scan_where = true;
      }
    },
    scanloc() {
      if (
        this.forms.is_face_scan_where == false &&
        this.forms.is_face_all_over == false
      ) {
        this.forms.is_face = false;
      }
    },
    save_both() {
      if (this.DataItems.length == 0) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Atleast  Proof of Work Configuration   Required",
        };
      } else {
        this.updateScanAnyWhereList();
        this.proof_form();
      }

      this.$refs.form1.resetValidation();
    },
    async proof_form() {
      this.forms = Object.assign({
        ...this.forms,
        is_face_all_over: this.forms.is_face
          ? this.forms.is_face_all_over
          : false,
        is_face_scan_where: this.forms.is_face
          ? this.forms.is_face_scan_where
          : false,
      });
      try {
        await API.graphql(
          graphqlOperation(proof_of_prezence_settings, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              forms_enable: this.forms,
              is_proof_of_work_enable: this.proofwork,
            },
          })
        );
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Updated successfully",
        };
        this.overlay = false;
        this.isLoading = false;

        this.GetCurrentUserDetailsnew();
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbar/showMessage", {
          content: error.errors[0].message,
          color: "red",
        });
        this.isLoading = false;
      }
    },
    addData(addCaptureValue) {
      if (this.$refs.form1.validate()) {
        var key_object = {
          work_type: addCaptureValue.trim(),
          attachments_supports: this.attachements,
          is_free_text: true,
          master_form_id: null,
          // id: this.DataItems.length + 1,
        };
        var compare = this.DataItems.some(
          (obj) => obj.work_type === addCaptureValue
        );
        if (compare == false) {
          this.DataItems.push(key_object);
          this.attachements = "";
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Proof of Work Configuration  Added Please Save",
          };
        } else {
          this.attachements = "";
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Proof of Work Configuration  already exist",
          };
        }
        this.$refs.form1.resetValidation();
        this.addCaptureValue = "";
      }
    },

    delete_feature(item) {
      let index = this.DataItems.findIndex(
        (x) => x.work_type == item.work_type
      );
      if (index > -1) {
        if (this.DataItems[index].master_form_id == null) {
          delete this.DataItems[index];
          this.DataItems = this.DataItems.filter(Boolean);
          this.status = true;
        }
      }
      this.Formdeletion = false;
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Proof of Work Configuration Deleted Please Save",
      };
      // for (let i = 0; i < this.DataItems.length; i++) {
      //   if (
      //     item.work_type == this.DataItems[i].work_type &&
      //     this.DataItems[i].master_form_id == null
      //   ) {
      //     this.DataItems.splice(i, 1);
      //     // status =true;
      //   }
      // }
    },

    async getScanAnyWhereList() {
      this.overlay = true;
      try {
        var inputParams = {
          action_type: "LIST",
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
        };
        let result = await API.graphql(
          graphqlOperation(scan_any_where_response_form_list_or_edit, {
            input: inputParams,
          })
        );
        this.overlay = false;
        // console.log(
        //   JSON.parse(result.data.scan_any_where_response_form_list_or_edit)
        //     .Items[0].list_response_message
        // );

        // this.DataItems = JSON.parse(
        //   result.data.scan_any_where_response_form_list_or_edit
        // ).Items[0].list_response_message;
        var response = JSON.parse(
          result.data.scan_any_where_response_form_list_or_edit
        ).Items[0].list_response_message;
        this.DataItems = response == undefined ? [] : response;

        // console.log(
        //   JSON.parse(result.data.scan_any_where_response_form_list_or_edit)
        //     .Items[0].list_response_message
        // );
        // this.DataItems = JSON.parse(
        //   result.data.scan_any_where_response_form_list_or_edit
        // ).Items[0].response_message.map((val, i) => {
        //   return {
        //     name: val,
        //     index: i,
        //   };
        // });
        this.feedid = JSON.parse(
          result.data.scan_any_where_response_form_list_or_edit
        ).Items[0].feed_id;
      } catch (err) {
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
      }
    },
    async updateScanAnyWhereList() {
      // console.log(this.DataItems);
      this.saveloader = true;
      try {
        var inputParams = {
          action_type: "EDIT",
          feed_id: this.feedid,
          list_response_message: JSON.stringify(this.DataItems),
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
        };
        await API.graphql(
          graphqlOperation(scan_any_where_response_form_list_or_edit, {
            input: inputParams,
          })
        );
        this.getScanAnyWhereList();
        this.$refs.form.reset();
        this.saveloader = false;
      } catch (err) {
        this.saveloader = false;
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
      }
    },
  },
};
</script>

<style>
</style>