<template>
  <div>
    <v-card class="elevation-2 overflow-y-auto pb-4" :height="cardHeight">
      <v-toolbar dense flat>
        <v-spacer />
        <v-btn
          dark
          :disabled="formFields.length == 0"
          @click="preview_action()"
          class="text-capitalize gradient-bg"
          >Preview</v-btn
        >
        <v-btn
          dark
          :disabled="formFields.length == 0"
          @click="save_actions()"
          class="text-capitalize ml-2 gradient-bg"
          >Save</v-btn
        >
      </v-toolbar>
      <v-card-text>
        <v-row no-gutters id="draggable-row" @dragover="allowDrop" @drop="drop">
          <v-col
            v-for="(index, Idx) in formFields"
            :key="Idx"
            :cols="Idx === 0 ? '12' : '12 mt-n8'"
            sm="12"
            xs="12"
            md="12"
            :draggable="true"
            @dragstart="dragStart(index, Idx)"
            @dragend="dragEnd(index, Idx)"
            @dragover="dragOver(Idx)"
          >
          <TextField
              v-if="
                index.type == 'TEXTFIELD' ||
                index.type == 'EMAIL' ||
                index.type == 'NAME' ||
                index.type == 'MOBILENUMBER'
              "
              :index="index"
              @clicked="emit_fields"
              v-on:editField="get_edit_field"
            />
            <NumberField
              v-if="index.type == 'NUMBER'"
              :index="index"
              @clicked="emit_fields"
              v-on:editField="get_edit_field"
            />
            <CheckboxField
              v-if="index.type == 'CHECKBOX'"
              :index="index"
              @clicked="emit_fields"
              v-on:editField="get_edit_field"
            />
            <TextAreaField
              v-if="index.type == 'PARAGRAPH'"
              :index="index"
              @clicked="emit_fields"
              v-on:editField="get_edit_field"
            />
            <DateField
              v-if="index.type == 'DATE'"
              :index="index"
              @clicked="emit_fields"
              v-on:editField="get_edit_field"
            />
            <DropdowmField
              v-if="index.type == 'DROPDOWN'"
              :index="index"
              v-on:editField="get_edit_field"
              @clicked="emit_fields"
            />
            <TimePicker
              v-if="index.type == 'TIME'"
              :index="index"
              @clicked="emit_fields"
              v-on:editField="get_edit_field"
            />
            <RatingFile
              v-if="index.type == 'RATING'"
              :index="index"
              @clicked="emit_fields"
              v-on:ratingEdit="get_edit_field"
            />
            <DividerComp
              v-if="index.type == 'DIVIDER'"
              :index="index"
              @clicked="emit_fields"
            />
            <FlipSwitch
              v-if="index.type == 'FLIPSWITCH'"
              :index="index"
              @clicked="emit_fields"
              v-on:editField="get_edit_field"
            />
            <LabelField
              v-if="index.type == 'LABEL'"
              :index="index"
              v-on:labelEdit="get_edit_field"
              @clicked="emit_fields"
            />
            <DelimiterLine
              v-if="index.type == 'DELIMITER'"
              :index="index"
              @clicked="emit_fields"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="Formsubmit" persistent width="420px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0 white--text">
          <v-toolbar-title>Form </v-toolbar-title>
          <!-- <v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          > -->
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <div class="mt-5">
            Could you please confirm that you want to save the Form?
          </div>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="mt-3">
            <v-btn
              color="primary"
              class="white--text"
              @click="close_freeforms()"
              small
              >No</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              @click="save_freeforms()"
              small
              >Yes</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <div v-if="componentCheck == 1">
      <FormPreview
        :previewDialog="previewDialog"
        :formFields="formFields"
        @clicked="previewDialog = false"
      />
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import VueDraggable from "vue-draggable";
import { API, graphqlOperation } from "aws-amplify";
import { create_forms } from "@/graphql/mutations.js";
import TextField from "@/components/FormDesigner/FormFields/TextField.vue";
import NumberField from "@/components/FormDesigner/FormFields/NumberField.vue";
import CheckboxField from "@/components/FormDesigner/FormFields/CheckboxField.vue";
import TextAreaField from "@/components/FormDesigner/FormFields/TextAreaField.vue";
import DateField from "@/components/FormDesigner/FormFields/DateField.vue";
import DropdowmField from "@/components/FormDesigner/FormFields/DropdowmField.vue";
import TimePicker from "@/components/FormDesigner/FormFields/TimePicker.vue";
import RatingFile from "@/components/FormDesigner/FormFields/RatingFile.vue";
import DividerComp from "@/components/FormDesigner/FormFields/DividerComp.vue";
import FlipSwitch from "@/components/FormDesigner/FormFields/FlipSwitch.vue";
import LabelField from "@/components/FormDesigner/FormFields/LabelField.vue";
import DelimiterLine from "@/components/FormDesigner/FormFields/DelimiterLine.vue";
import FormPreview from "@/components/Dialogs/FormPreview.vue";

export default {
  props: {
    formFields: Array,
    namesobj: Object,
  },
  components: {
    TextField,
    LabelField,
    NumberField,
    CheckboxField,
    TextAreaField,
    DateField,
    DropdowmField,
    TimePicker,
    RatingFile,
    DividerComp,
    FlipSwitch,
    FormPreview,
    VueDraggable,
    DelimiterLine,
  },
  data() {
    return {
      cardHeight: 0,
      formCreationDialog: false,
      Formsubmit: false,
      formDataArray: [],
      componentCheck: 0,
      previewDialog: false,
    };
  },

  mounted() {
    this.cardHeight = window.innerHeight - 180;
    // console.log(this.namesobj);
  },
  methods: {
    allowDrop(event) {
      event.preventDefault();
    },
    get_edit_field(value) {
      this.$emit("editValue", value);
    },

    dragStart(field, index) {
      this.draggedField = index;
    },

    dragEnd(index, Idx) {
      this.draggedField = null;
      if (this.dragOverIndex !== null) {
        // Move the field to the new position in the formFields array
        const field = this.formFields[Idx];
        this.formFields.splice(Idx, 1);
        this.formFields.splice(this.dragOverIndex, 0, field);
      }
      this.dragOverIndex = null;
    },

    dragOver(Idx) {
      this.dragOverIndex = Idx;
    },

    drop(event) {
      event.preventDefault();
      // if (this.draggedField) {
      //   const { field, index } = this.draggedField;

      //   // Add the field to the formFields array where it was dropped (at the end in this example).
      //   this.formFields.push(field);

      //   // Optionally, you can remove the field from its original position (if needed).
      //   this.formFields.splice(index, 1);

      //   // Reset the draggedField data property.
      //   // this.draggedField = null;
      // }
    },
    close_dialog() {
      this.Formsubmit = false;
    },
    close_freeforms() {
      this.Formsubmit = false;
    },
    emit_fields(Toggle, Index) {
      if (Toggle == 1) {
        this.formFields.splice(this.formFields.indexOf(Index), 1);
      }
    },
    preview_action() {
      this.componentCheck = 1;
      // console.log(this.formFields);
      this.previewDialog = true;
    },
    // save_actions() {
    //   this.componentCheck = 1;
    //   this.formCreationDialog = true;
    //   this.formDataArray = this.formFields;
    //   console.log(this.formDataArray);

    //   // this.console.log(JSON.stringify(this.formFields));
    // },
    save_actions() {
      this.Formsubmit = true;
    },
    async save_freeforms() {
      this.loading = true;
      // console.log(this.namesobj);
      try {
        let result = await API.graphql(
          graphqlOperation(create_forms, {
            input: {
              work_type: this.namesobj.worktype,
              form_name: this.namesobj.name,

              form_created_by:
                this.$store.getters.get_org_user_details[0].user_email_id,
              form_template: JSON.stringify(this.formFields),
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );
        // console.log(result);
        var response = JSON.parse(result.data.create_forms);
        this.loading = false;
        if (response.Status == "SUCCESS") {
          this.Formsubmit = false;
          this.$emit("successMsg", response.Message);
          this.loading = false;
        } else {
          this.$emit("errorMsg", response.Message);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.$emit("errorMsg", error.errors[0].message);
        this.loading = false;
      }
    },
  },
};
</script>