var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{staticClass:"ml-4 mt-2"},[_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.toggle_exclusive),callback:function ($$v) {_vm.toggle_exclusive=$$v},expression:"toggle_exclusive"}},[_c('v-btn',{class:{
            'white--text': _vm.isActive('custom_settings'),
            'black--text': !_vm.isActive('custom_settings'),
          },style:(_vm.isActive('custom_settings')
              ? { backgroundColor: '#E65100' }
              : { backgroundColor: 'white' }),attrs:{"small":"","text":"","value":"custom_settings"}},[_vm._v(" Custom Settings ")]),_c('v-btn',{class:{
            'white--text': _vm.isActive('form_settings'),
            'black--text': !_vm.isActive('form_settings'),
          },style:(_vm.isActive('form_settings')
              ? { backgroundColor: '#E65100' }
              : { backgroundColor: 'white' }),attrs:{"small":"","text":"","value":"form_settings"}},[_vm._v(" Form Design ")])],1)],1),_c('v-spacer')],1),(_vm.toggle_exclusive == 'custom_settings')?_c('div',{staticClass:"text-left"},[_c('ProofCustomSetting')],1):_vm._e(),(_vm.toggle_exclusive == 'form_settings')?_c('div',{staticClass:"text-left"},[_c('ProofFormSetting')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }