<template>
  <div>
    <v-dialog v-model="taggingForm" persistent width="400px">
      <v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title class="white--text">Tag Form</v-toolbar-title>
        <v-spacer />

        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-card-text class="mt-5">
          <div>
            <b> Tag a form for a Work Type {{ tagitem.work_type }} ? </b>
          </div>
          <v-form ref="form">
            <v-select
              outlined
              dense
              label="Forms"
              v-model="formName"
              :rules="[(v) => !!v || 'Required']"
              style="max-width: 470px"
              item-text="form_name"
              item-value="form_id"
              class="mt-2"
              :items="memberItems"
            />

            <v-card-actions class="justify-center">
              <v-btn
                :loading="adminLoading"
                @click="validate_add_admin()"
                class="gradient-bg white--text"
                v-if="formName != ''"
              >
                <!-- <v-icon class="mr-2">mdi-plus</v-icon> -->
                Tag
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
      
      <script>
import { API, graphqlOperation } from "aws-amplify";
import { tag_untag_forms } from "@/graphql/mutations.js";
import { list_forms_for_tags } from "@/graphql/queries.js";
export default {
  props: {
    taggingForm: Boolean,
    tagitem: Object,
  },
  data: () => ({
    formName: "",
    adminLoading: false,
    memberItems: [],
  }),
  watch: {
    taggingForm: {
      async handler() {
        if (this.taggingForm == true) {
          this.get_members_by_status();
          //   this.AdminGroup = this.tagitem.group_name;
          //   this.groupdescrip = this.tagitem.group_description;
          //   this.member_name = this.tagitem.group_admin;
          // console.log(this.tagitem, "s");
        }
      },
      immediate: true,
    },
  },

  methods: {
    validate_add_admin() {
      if (this.$refs.form.validate()) {
        this.delete_freeforms();
      }
    },
    async get_members_by_status() {
      try {
        let result = await API.graphql(
          graphqlOperation(list_forms_for_tags, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );

        var array = JSON.parse(result.data.list_forms_for_tags).data;
        // console.log(array, "ss");
        // // (this.memberItems = ),
        array.forEach((element) => {
            if(element.work_type == undefined){
          this.memberItems.push({
            form_name: element.form_name,
            form_id: element.form_id,
          });
        }
        });
      } catch (err) {
        console.log("error", err);
        this.$emit("errorMsg", err.errors[0].message);
      }
    },
    async delete_freeforms() {
      this.adminLoading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(tag_untag_forms, {
            input: {
              action_type: "TAG",
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              form_id: this.formName,
              work_type: this.tagitem.work_type,
            },
          })
        );
        // console.log(result);
        var response = JSON.parse(result.data.tag_untag_forms);
        this.adminLoading = false;
        if (response.Status == "SUCCESS") {
          this.$emit("successMsg", response.Message);
          this.adminLoading = false;
        } else {
          this.$emit("errorMsg", response.Message);
          this.adminLoading = false;
        }
      } catch (error) {
        console.log(error);
        this.$emit("errorMsg", error.errors[0].message);
        this.adminLoading = false;
      }
    },
    close_dialog() {
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.$emit("clicked", 0);
    },
  },
};
</script>
      
      <style>
</style>