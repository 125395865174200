<template>
  <div>
    <v-dialog v-model="editforms" persistent width="1200px">
      <v-toolbar dense class="gradient-bg elevation-0">
        <v-toolbar-title class="white--text">Edit Forms</v-toolbar-title>
        <v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3">
          mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-card-text class="mt-3">
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" xs="12" lg="4" fill-height>
              <v-card
                v-if="componentCheck == 0"
                :height="cardHeight"
                flat
                over
                class="card-content"
              >
                <!-- <div class="text-center primary--text" style="font-size: 25px">
                  <b>Add Fields</b>
                </div> -->
                <v-row class="mt-2" no-gutters>
                  <v-col
                    v-for="item in inputFields"
                    class=""
                    :key="item.type"
                    cols="12"
                  >
                    <v-btn
                      @click="fetch_selected_item(item)"
                      class="ma-1 ml-2 text-left"
                      style="
                        width: 250px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                      "
                    >
                      <v-icon left> {{ item.icon }} </v-icon>
                      {{ item.label }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                flat
                :height="cardHeight"
                class="elevation-0 card-content"
                v-if="componentCheck == 1"
              >
                <v-toolbar dense class="elevation-0">
                  <div><b>Edit Field</b></div>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    class="gradient-bg white--text"
                    @click="back_action()"
                    ><v-icon>mdi-chevron-double-left</v-icon> Back</v-btn
                  >
                </v-toolbar>
                <v-card-text>
                  <v-form ref="form1">
                    <v-text-field
                      dense
                      readonly
                      v-model="selectedItem.type"
                      label="Field Type"
                      outlined
                    />

                    <v-text-field
                      dense
                      outlined
                      v-model="labelToDisplay"
                      label="Label To Display"
                      @input="get_input_value(labelToDisplay)"
                      :rules="rules_scan_anywhere"
                      :readonly="isReadonly"
                    />
                    <v-checkbox
                      v-model="madatoryField"
                      label="Mandatory Field*"
                      v-if="editable == false"
                      v-show="
                        selectedItem.backend_type != 'label' &&
                        selectedItem.backend_type != 'delimiter'
                      "
                    ></v-checkbox>
                    <!-- <v-checkbox
                      v-model="Readonly"
                      label="Edit Field*"
                      class="mt-n3"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="visibel"
                      label="Visible Field*"
                      class="mt-n3"
                    ></v-checkbox> -->
                  </v-form>
                  <div v-if="selectedItem.type == 'DROPDOWN'">
                    <v-form ref="form">
                      <v-row no-gutters>
                        <v-col>
                          <v-text-field
                            outlined
                            dense
                            v-model="fieldData"
                            :rules="[(v) => !!v || 'Required']"
                            label="Options"
                            style="width: 210px"
                          />
                        </v-col>
                        <v-col>
                          <v-btn
                            @click="add_options(fieldData)"
                            class="elevation-0 ml-5"
                            fab
                            x-small
                          >
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in globalOptions"
                        :key="item"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon @click="delete_data(index)" small
                            >mdi-delete</v-icon
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </div>

                  <v-btn
                    class="gradient-bg white--text"
                    @click="add_field()"
                    v-if="editable == false"
                    style="width: 250px"
                    >Add Field</v-btn
                  >
                </v-card-text>
              </v-card>
              <v-card
                flat
                :height="cardHeight"
                class="elevation-0 card-content"
                v-if="componentCheck == 2"
              >
                <v-toolbar dense class="elevation-0">
                  <div><b>Rearrange</b></div></v-toolbar
                >

                <draggable
                  :list="listnew"
                  :disabled="!enabled"
                  class="list-group"
                  ghost-class="ghost"
                  :move="checkMove"
                  @start="dragging = true"
                  @end="dragging = false"
                >
                  <div
                    class="list-group-item ml-5 pa-2 mr-5 mb-2"
                    style="
                      font-size: medium;
                      border: 1px solid #ccc;
                      padding: 8px;
                    "
                    v-for="element in listnew"
                    :key="element.label"
                  >
                    {{ element.label }}
                  </div>
                </draggable>
                <v-btn
                  class="gradient-bg white--text mt-2 ml-5"
                  @click="Save_field()"
                  style="width: 200px"
                  >Save Field</v-btn
                >
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="8" xs="12">
              <!-- <template> -->
              <EditFormView
                :formFields="formFields"
                v-on:errorMsg="error_info"
                @reverse="store"
                v-on:successMsg="success_info"
                v-on:editValue="get_edit_field"
                :key="formFieldsKey"
                :formitems="formitems"
              />
              <!-- </template> -->
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import SnackBar from "@/components/SnackBar.vue";
import EditFormView from "@/components/Dialogs/EditFormView.vue";
export default {
  components: {
    EditFormView,
    draggable,
    SnackBar,
  },
  props: {
    editforms: Boolean,
    formitems: Object,
    // namesobj: Object,
    // :namesobj="namesobj"
  },
  data() {
    return {
      formAction: "",
      SnackBarComponent: {},
      enabled: true,
      formFieldsKey: 0,
      rules_scan_anywhere: [
        (v) =>
          (typeof v === "string" && v.trim().length !== 0) ||
          " Filed cannot be empty",
        (v) =>
          /^[a-z\sA-Z]{1,40}$/.test(v) ||
          "Filed cannot contain special characters and more than 40 characters ",
      ],
      inputFields: [
        {
          type: "TEXTFIELD",
          icon: "mdi-text-recognition",
          label: "Text",
          backend_type: "string",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "PARAGRAPH",
          icon: "mdi-text-box",
          label: "Paragraph",
          backend_type: "paragraph",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "NUMBER",
          icon: "mdi-numeric",
          label: "Number",
          backend_type: "number",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "MOBILENUMBER",
          icon: "mdi-phone",
          label: "Mobile Number",
          backend_type: "number",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "EMAIL",
          icon: "mdi-email",
          label: "Email",
          backend_type: "email",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "DROPDOWN",
          icon: "mdi-format-list-bulleted-square",
          label: "Dropdown",
          backend_type: "single_choice",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "DELIMITER",
          icon: "mdi-format-line-spacing",
          label: "Delimiter",
          backend_type: "delimiter",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "LABEL",
          icon: "mdi-label",
          label: "Label",
          backend_type: "label",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "CHECKBOX",
          icon: "mdi-checkbox-outline",
          label: "Checkbox",
          backend_type: "checkbox",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "TIME",
          icon: "mdi-timer",
          label: "Time",
          backend_type: "time",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "DATE",
          icon: "mdi-calendar",
          label: "Date",
          backend_type: "date",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        // {
        //   type: "NAME",
        //   icon: "mdi-account",
        //   label: "Name",
        //   backend_type: "string",
        //   is_displayed: true,
        //   is_readonly: false,
        //   is_required: true,
        // },
        {
          type: "FLIPSWITCH",
          icon: "mdi-toggle-switch",
          label: "Flip Switch",
          backend_type: "boolean",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
        {
          type: "RATING",
          icon: "mdi-star-outline",
          label: "Ratings",
          backend_type: "rating",
          is_displayed: true,
          is_readonly: false,
          is_required: true,
        },
      ],
      listnew: [],
      cardHeight: 0,
      dragging: false,
      componentCheck: 0,
      selectedItem: "",
      labelToDisplay: "",
      formFields: [],
      count: 0,
      fieldData: "",
      globalOptions: [],
      editable: false,
    };
  },
  watch: {
    editforms: {
      handler() {
        this.formFieldsKey++;
        this.formFields = [];
        // console.log( this.formFields);
        this.cardHeight = window.innerHeight - 170;
        this.formFields = this.formitems.form_desgin;
        // console.log( this.formFields);
        // console.log(this.formitems);
      },
      immediate: true,
    },
  },
  computed: {
    isReadonly() {
      return this.selectedItem.backend_type === "divider";
    },
  },
  methods: {
    get_edit_field(value) {
      this.selectedItem = {};
      this.labelToDisplay = "";
      this.componentCheck = true;
      this.selectedItem = value;
      this.labelToDisplay = value.label;
      this.madatoryField = value.is_required;
      this.editable = true;
      this.globalOptions = value.fieldOptions;
    },
    get_input_value(value) {
      if (this.editable == true) {
        this.formFields.forEach((element) => {
          if (element.label == this.selectedItem.label) {
            element.label = value;
            element.displayLabel = value;
          }
        });
      }
    },
    error_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
    },

    store(val) {
      this.componentCheck = 2;

      this.listnew = val;
      // console.log(this.listnew);
    },
    Save_field() {
      this.formFields = this.listnew;
      this.componentCheck = 0;
    },
    success_info(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        SnackbarText: val,
        timeout: 5000,
        Top: true,
      };
      this.$emit("clicked", 0);
    },
    close_dialog() {
      this.formFields = [];
      this.$emit("clicked", 0);
    },
    fetch_selected_item(value) {
      this.selectedItem = {};
      this.labelToDisplay = "";
      this.componentCheck = 1;
      this.selectedItem = value;
      this.labelToDisplay = value.label;
      this.madatoryField = true;
      this.Readonly = true;
      this.visibel = true;
      this.globalOptions = [];
    },

    add_field() {
      // console.log(this.globalOptions);
      if (this.selectedItem.type == "DROPDOWN") {
        if (this.globalOptions.length >= 1) {
          if (this.$refs.form1.validate()) {
            this.selectedItem.displayLabel = "";

            this.formFields.push({
              type: this.selectedItem.type,
              label: this.labelToDisplay,
              icon: this.selectedItem.icon,
              backend_type: this.selectedItem.backend_type,
              displayLabel: this.labelToDisplay,
              is_displayed: this.visibel,
              is_readonly: this.Readonly,
              is_required: this.madatoryField,
              fieldOptions: this.globalOptions,
            });
            this.globalOptions = [];
          }
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: "Select atleast One Option",
            timeout: 5000,
            Top: true,
          };
        }
      } else if (this.$refs.form1.validate()) {
        this.selectedItem.displayLabel = "";
        if (
          this.selectedItem.type == "DELIMITER" ||
          this.selectedItem.type == "LABEL"
        ) {
          this.madatoryField = false;
        }
        this.formFields.push({
          type: this.selectedItem.type,
          label: this.labelToDisplay,
          icon: this.selectedItem.icon,
          backend_type: this.selectedItem.backend_type,
          displayLabel: this.labelToDisplay,
          is_displayed: this.visibel,
          is_readonly: this.Readonly,
          is_required: this.madatoryField,
          fieldOptions: this.globalOptions,
        });
        this.globalOptions = [];
      }
      // console.log(this.formFields);
    },

    back_action() {
      if (this.editable == true) {
        if (this.selectedItem.type == "DROPDOWN") {
          if (this.globalOptions.length == 0) {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Select atleast One Option",
              timeout: 5000,
              Top: true,
            };
          } else if (this.labelToDisplay == "") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Enter the label to display",
              timeout: 5000,
              Top: true,
            };
          } else {
            this.componentCheck = 0;
            this.editable = false;
            this.labelToDisplay = "";
          }
        } else {
          if (this.labelToDisplay == "") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Enter the label to display",
              timeout: 5000,
              Top: true,
            };
          } else {
            this.componentCheck = 0;
            this.editable = false;
            this.labelToDisplay = "";
          }
        }
      } else {
        this.componentCheck = 0;
        this.editable = false;
        this.labelToDisplay = "";
      }
    },

    add_options(value) {
      if (this.$refs.form.validate()) {
        if (this.globalOptions.includes(value)) {
          this.$store.commit("snackbar/showMessage", {
            content: "Already exists ",
            color: "red",
          });
        } else {
          this.globalOptions.push(value);
        }
      }
      this.fieldData = "";
      this.$refs.form.reset();
    },

    delete_data(val) {
      this.globalOptions.splice(val, 1);
    },
  },
};
</script>
  <style scoped>
.card-content {
  overflow: auto; /* Add overflow property to handle content overflow */
}
</style>